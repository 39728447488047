import React from 'react'
import styled from 'styled-components'

const AppContainer = styled.div`
  margin: 0 auto;
  font-size: 1.1rem;
  background-color: white;
`

export default AppContainer
